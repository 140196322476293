import React from "react";

export const ProjectItem = (props) => {
  const {
    title,
    imgURL,
    technologies,
    finishDate,
    description,
    projectURL,
    isEnabled,
    isVisible,
  } = props.item;

  const openInNewTab = () => {
    if (isEnabled) {
      const newWindow = window.open(
        projectURL,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  return isVisible ? (
    <button className='project-link-container' onClick={openInNewTab}>
      <div className='project-item'>
        <img
          src={require(`../../images/${imgURL}`)}
          alt={imgURL}
          className='project-img'
        />
        <div className='project-content'>
          <div className='project-header'>
            <div className='project-header-left'>
              <h3 className='project-title'>{title}</h3>
              {isEnabled && <p className='project-link'>{projectURL}</p>}
            </div>
            <p className='project-date'>{finishDate}</p>
          </div>
          <div className='project-technologies-list'>
            {technologies.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
          <p className='project-description'>{description}</p>
        </div>
      </div>
    </button>
  ) : null;
};
