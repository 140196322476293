import React from "react";

export const AboutSecondary = () => {
  return (
    <div className='about-secondary'>
      <div id='about-a'>
        <h3 className='about-secondary-header'>Languages</h3>
        <ul className='about-list'>
          <li>JavaScript</li>
          <li>HTML</li>
          <li>CSS</li>
          <li>Java</li>
          <li>Python</li>
          <li>C++</li>
          <li>C</li>
        </ul>
      </div>
      <div id='about-b'>
        <h3 className='about-secondary-header'>Technologies</h3>
        <ul className='about-list'>
          <li>React</li>
          <li>PostgresSQL</li>
          <li>Git</li>
          <li>NodeJS</li>
          <li>Vim</li>
          <li>Bootstrap</li>
          <li>Flask</li>
          <li>GWT</li>
        </ul>
      </div>
      <div id='about-c'>
        <h3 className='about-secondary-header'>Awards / Acknowledgements</h3>
        <ul className='about-list'>
          <li>Placed in top 3 in 3 hackathons</li>
          <li>President's Honour Roll in freshman year of University</li>
          <li>Dean's List Honour Roll in freshman year of University</li>
          <li>Placed 2nd in ICPC Local Qualifier in 2019</li>
          <li>Graduated High School with Honours</li>
          <li>Department of Mathematics Award at High School Graduation</li>
        </ul>
      </div>
    </div>
  );
};
