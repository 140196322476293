import React, { useContext, Fragment } from 'react';
import AppContext from '../../context/appContext';
import { Home } from './Home';
import { ProjectsPage } from './ProjectsPage';
import { About } from './About';
import { Footer } from '../layout/Footer';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

export const Page = () => {
  const appContext = useContext(AppContext);
  const { page } = appContext;
  return (
    <Fragment>
      <div id='page-container'>
        <CSSTransitionGroup
          transitionName='page-transition'
          transitionEnterTimeout={250}
          transitionLeaveTimeout={250}
        >
          {page === 'home' && <Home key={0} />}
          {page === 'projects' && <ProjectsPage key={1} />}
          {page === 'about' && <About key={2} />}
        </CSSTransitionGroup>
        <Footer />
      </div>
    </Fragment>
  );
};
