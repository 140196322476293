import React from "react";
import { ProjectItem } from "./ProjectItem";
import projects from "../../data/projects.json";

export const Projects = () => {
  // Retrieve lists of featured and regular projects from projects.json
  const { featured, other } = projects;

  const getProjectItems = (projectsList) =>
    projectsList.map((item, index) => {
      const itemObject = {
        title: item.title,
        imgURL: item.imgURL,
        technologies: item.technologies,
        finishDate: item.finishDate,
        description: item.description,
        projectURL: item.projectURL,
        isEnabled: item.isEnabled,
        isVisible: item.isVisible,
      };
      return <ProjectItem key={index} item={itemObject} />;
    });

  const featuredProjects = getProjectItems(featured);
  const otherProjects = getProjectItems(other);

  return (
    <div className='projects'>
      <h1 className='section-title'>Featured Projects</h1>
      <div id='projects-container'>{featuredProjects}</div>
      <h1 className='section-title'>Other Projects</h1>
      <div id='projects-container'>{otherProjects}</div>
    </div>
  );
};
