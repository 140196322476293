import React from "react";

export const AboutPrimary = () => {
  return (
    <div className='about-primary'>
      <h1>About Me</h1>
      <div className='about-primary-flex'>
        <img
          src={require("../../images/portrait.jpg")}
          alt='Portrait'
          id='portrait'
        />
        <div id='bio'>
          <p>
            I'm Brady Malott, a junior computer science undergrad at the
            University of Windsor. I am an aspiring full stack web developer and
            have been practicing frontend and backend web development using
            technologies such as React, Django, PostgresSQL, and more.
          </p>
          <p>
            I have been programming since my third year of high school, and have
            developed strong skills in Java, Python, Javascript, HTML, and CSS.
            I enjoy learning new skills as a programmer, especially through
            participating in hackathons, working on solo or team based projects,
            and competing in programming competitions.
          </p>
          <p>
            Currently, I am seeking an internship for the summer of 2022. Last
            spring I worked with a group of students on a web app called UTable,
            which helps University of Windsor students create course timetables
            and rate courses. Through this project, I further developed my
            skills in React and Django and gained experience working on a team.
            Over the summer of 2021 I worked as a co-op web developer at Hiram
            Walker & Sons, where I did frontend and backend Java programming
            with GWT and worked with an Oracle database.
          </p>
        </div>
      </div>
    </div>
  );
};
