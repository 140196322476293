import React, { useState } from 'react';

export const Footer = () => {
  const [bannerVisible, setBannerVisible] = useState(false);

  // Copy text to clipboard (used for getting discord tag)
  const copyToClipboard = (string) => {
    // Copy the discord tag
    const el = document.createElement('textarea');
    el.value = string;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    // Show the banner
    if (!bannerVisible) {
      setBannerVisible(true);
      setTimeout(() => setBannerVisible(false), 2000);
    }
  };

  const composeEmail = (email) => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <div className='footer'>
      <div id='footer-banner-wrapper'>
        {bannerVisible && (
          <>
            <div></div>
            <div></div>
            <div id='discord-banner'>Copied Discord Tag</div>
            <div></div>
          </>
        )}
      </div>
      <div className='footer-container'>
        <a
          className='footer-item'
          href='https://github.com/Brady-Malott'
          target='_blank'
          rel='noreferrer'
        >
          <i className='fab fa-github fa-2x' id='github-icon'></i>
        </a>
        <a
          className='footer-item'
          href='https://www.linkedin.com/in/brady-malott-52003b193/'
          target='_blank'
          rel='noreferrer'
        >
          <i className='fab fa-linkedin fa-2x' id='linkedin-icon'></i>
        </a>
        <button
          className='footer-item'
          href='#!'
          onClick={() => copyToClipboard('Nin10dough#6324')}
        >
          <i className='fab fa-discord fa-2x' id='discord-icon'></i>
        </button>
        <a
          className='footer-item'
          href='#!'
          onClick={() => composeEmail('brady.malottwork@gmail.com')}
        >
          <i className='far fa-envelope fa-2x' id='email-icon'></i>
        </a>
      </div>
    </div>
  );
};
